<template>
  <div class="w-100">
    <md-card
      class="mx-auto w-auto d-block text-center"
      style="max-width: 800px"
    >
      <md-card-header class="py-5">
        <md-icon class="md-size-3x text-success">
          done_all
        </md-icon>
        <h3 class="title">
          Thank you very much!
        </h3>
        <h5 class="category">
          Your Terms & Conditions have been signed succesfully.
        </h5>
      </md-card-header>
    </md-card>
  </div>
</template>

<script>

export default {
  data: () => ({
    loading: true,
  }),
  mounted() {
    setTimeout(() => {
      this.guestRequest(
        `programmes/${this.$route.params.programme_id}/signed`,
        'post',
        null,
        () => {
          this.loading = false;
        },
      );
    }, 1500);
  },
};
</script>

<style scoped>
  h3.title {
    color: #3c4858 !important;
    margin-top: 10px;
  }
</style>
